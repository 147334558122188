.container{
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 3;

  @media screen and (max-width: 1024px){
    display: none;
    position: relative;
    bottom: 50px;
    right: 0;
    width: fit-content;
    width: -moz-fit-content;
    margin: 0 auto;
    z-index: -1;
    padding-bottom: 140px;
  }
}