.step-input-component{
  &-container{
    width: 100%;
    max-width: 960px;
    flex-direction: column;
    display: flex;
    align-items: center;

    // &.bottom-padding{
    //   padding-bottom: 150px;
    // }
    padding-top: 0;

    padding-bottom: 150px;

    @media screen and (max-width: 1024px){
      padding-top: 0px;

      // &.bottom-padding{
      //   padding-bottom: 0px;
      // }
      padding-bottom: 0px;
    }
  }
  &-holder{
    display: flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1024px){
      padding-bottom: 140px;
      max-width: 400px;
      width: 100%;
    }

    @media screen and (min-width: 1025px){
      width: 100%;
    }
  }
  &-option{
    &-container{
      width: 220px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 12px;
      background-color: var(--form-input-option-background);
      background-color: var(--input-background-color);
      cursor: pointer;
      margin: 10px 10px;
      border: solid 2px transparent;
      align-self: stretch;

      &.active{
        border-color: var(--brand-color);
        transform: scale(1.05);
        background-color: var(--form-input-option-active-background);
      }

      @media screen and (max-width: 1024px){
        width: calc(50% - 20px);
      }
    }
    &-image{
      &-holder {
        width: 100%;
        min-height: 218px;
        max-height: 218px;
        display: flex;
        background-color: rgba(255, 255, 255, 0.15);
      }
      width: 100%;
      height: 100%;
      max-width: 218px;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.25s ease-in;

      &.loaded{
        opacity: 1;
      }

      @media screen and (max-width: 1024px){
        min-height: 150px;
        max-height: 150px;
        min-width: 100%;
      }
    }
    &-label{
      font-size: 17px;
      font-weight: bold;
      color: var(--text-color-primary);
      color: var(--input-text-color);
      padding: 25px 35px;
      text-align: center;
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
  &-text-input{
    margin-top: max(15vh, 35px);
    min-width: 420px;
    max-width: 420px;

    @media screen and (max-width: 1024px){
      min-width: 100%;
      width: calc(100vw - 36px);
      max-width: 420px;
      margin-top: 0;
    }

    @media screen and (max-height: 764px) and (min-width: 1024px){
      margin-top: 35px;
    }
  }
}