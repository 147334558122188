.wizard-step-bar-component{
  &-container{
    width: 100%;
    height: 5px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--background-color);
    z-index: 1;
    &-background{
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      height: 5px;
      background-color: rgba(var(--title-color-r), var(--title-color-g), var(--title-color-b), 0.10);
    }
  }
  &-bar{
    transition: 0.25s ease;
    width: 0;
    height: 100%;
    display: flex;
    background-color: var(--button-background-color);
    z-index: 3;
  }
}