.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 420px;
  max-width: 420px;
  padding-top: 15px;

  @media screen and (min-width: 1025px){
    &.gridContainer{
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding-top: 0;
      min-width: 100%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1024px){
    min-width: 100%;
    width: calc(100vw - 36px);
    max-width: 475px;
    padding: 0 15px;
    padding-top: 15px;
  }
}
.option{
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: 1025px){
    &.gridOption{
      max-width: 405px;

      &:nth-child(even){
        margin-left: 12px;
        margin-bottom: 15px;
      }
    }
  }

  &:not(:last-child){
    margin-bottom: 15px
  }
}
.optionHolder{
  padding: 8px 24px 8px 8px;
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0 1px 4px 0 rgba(71, 84, 103, 0.25);
  background-color: var(--input-background-color, --form-input-option-background);
  width: 100%;
  display: flex;
  align-items: center;
  transition: var(--transition-framer-hover);
  min-height: 82px;
  
  &.optionHolderWithImage{
    min-height: 92px;
  }

  &:hover{
    box-shadow: 0 8px 16px 0 rgba(71, 84, 103, 0.12);
    transform: scale(1.03);

    & span {
      color: var(--input-text-color);
    }

    @media screen and (max-width: 1024px){
      box-shadow: 0 1px 4px 0 rgba(71, 84, 103, 0.25);
      transform: scale(1);
      color: var(--input-text-color);
    }

    & .multipleCheckbox{
      background-color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.12);
      border-color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.50);
    }
  }

  &:active{
    border-color: var(--button-background-color);
    box-shadow: 0 8px 16px 0 rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.12), 0 0 0 4px rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.2);
    transform: scale(1);

    & span {
      color: var(--input-text-color);
    }
  }

  &.optionActive{
    border-color: var(--button-background-color, --brand-color);
    transform: scale(1.05);

    & span {
      color: var(--input-text-color);
    }
  }
}

.optionLabel{
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.87);
  transition: var(--transition-framer-hover);
  margin-right: 14px;
}

.imageHolder{
  min-height: 72px;
  max-height: 72px;
  min-width: 72px;
  max-width: 72px;
  display: flex;
  margin-right: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.image{
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.25s ease-in;

  &.imageLoaded{
    opacity: 1;
  }
}

.noImageSpacer{
  margin-right: 14px;
}

.multipleCheckbox{
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--input-background-color);
  transition: var(--transition-framer-hover);
  border: 1.5px solid rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.30);

  &.multipleCheckboxActive{
    background-color: var(--button-background-color) !important;
    border-color: var(--button-background-color) !important;
  }
}

.multipleCheckboxIcon{
  width: 12px;
  height: auto;
  opacity: 0;
  transition: var(--transition-framer-hover);

  & path:nth-child(2) {
    stroke: var(--input-background-color);
  }

  &.multipleCheckboxIconActive{
    opacity: 1;
  }
}