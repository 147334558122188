.header-phone-cta-component{
  &-container{
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    @media screen and (min-width: 1025px){
      &:hover .header-phone-cta-component-action{
        background-color: var(--brand-color);

        & > svg path:nth-child(2) {
          fill: var(--white-color);
        }
      }
    }
  }
  &-left{
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    justify-content: flex-end;

    &-label{
      font-size: 16px;
      font-weight: 500;
      color: var(--title-color);
      text-align: right;

      @media screen and (max-width: 1024px){
        display: none;
      }

      &:not(:last-child){
        margin-bottom: 3px;
      }
    }

    &-phone{
      font-size: 16px;
      font-weight: 600;
      color: var(--button-background-color);
      text-align: right;

      @media screen and (max-width: 1024px){
        display: none;
      }

      &:not(:last-child){
        margin-bottom: 3px;
      }

      &-icon{
        height: 12px;
        width: auto;
        margin-right: 5px;
        & path {
          fill: var(--button-background-color);
        }
      }
    }
  }
  &-action{
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.12s ease-out;
    display: none;

    & > svg path{
      fill: var(--title-color);
    }

    @media screen and (max-width: 1024px){
      background-color: transparent;
      display: flex;
      min-width: 32px;
      min-height: 32px;
      max-height: 32px;
      max-width: 32px;
    }
  }
}