.back-button{
  &-component{
    min-width: 50px;
    min-height: 50px;
    max-height: 50px;
    max-width: 50px;
    border-radius: 50%;
    background-color: transparent;
    transition: 0.12s ease-in;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    & svg path {
      fill: var(--title-color);
    }
    @media screen and (min-width: 1025px){

      &:hover{
        background-color: rgba(var(--title-color-r), var(--title-color-g), var(--title-color-b), 0.06);
      }
      &:active{
        transition: 0.1s;
        background-color: rgba(var(--title-color-r), var(--title-color-g), var(--title-color-b), 0.1);
        transform: scale(0.85);
      }
    }

    @media screen and (max-width: 768px){
      min-width: 32px;
      min-height: 32px;
      max-height: 32px;
      max-width: 32px;
    }
  }
}