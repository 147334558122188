.container{
  display: flex;
  width: 100%;
}
.holder{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.splitterHolder{
  position: relative;
  width: 100%;
  display: flex;
  height: 7px;
}
.splitter{
  position: absolute;
  top: calc(-21px + 3.5px);
  left: calc(50% - 21px);
  min-width: 42px;
  max-width: 42px;
  min-height: 42px;
  max-height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--input-background-color);
  z-index: 2;
}
.splitterCircle{
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.1);
  z-index: 2;
}
.splitterIcon{
  width: 24px;
  height: auto;
  & path {
    fill: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.80);
  }
}