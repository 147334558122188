.input-component {
  &-container {
    position: relative;
    width: 100%;

    &:active{
      & > input {
        transition: 0.12s ease;
        box-shadow: 0 8px 16px 0 rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.12), 0 0 0 4px rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.2);
        border-color: var(--button-background-color);
      }
    }
  }
  &-input {
    padding: 29px 60px 11px 16px;
    font-size: 16px;
    font-weight: 500;
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    width: 100%;
    border-radius: var(--input-border-radius);
    border: solid 1px var(--input-border-color-inactive);
    border: solid 2px var(--input-background-color);
    transition: 0.25s ease;
    box-shadow: 0 1px 4px 0 rgba(71, 84, 103, 0.25);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }

    &.large{
      padding: 38px 60px 16px 20px;
      font-size: 18px;
      font-weight: 500;
      border-width: 2px;
    }

    &.error {
      border-color: var(--input-border-color-error) !important;

      &+label{
        color: var(--input-border-color-error) !important;
      }
    }

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(71, 84, 103, 0.12);

      &+label{
        color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.85);
      }
    }

    &:focus {
      border-color: var(--button-background-color);
      box-shadow: 0 0 10px 0 rgb(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 20%);

      &+.input-component-valid.active {
        opacity: 1;
      }

      &+label{
        color: var(--button-background-color) !important;
      }

      &.error{
        &+label{
          color: var(--input-border-color-error) !important;
        }
      }
    }
  }
  &-label{
    position: absolute;
    font-size: 16px;
    font-weight: 500;
    left: 15px;
    top: 22px;
    transition: var(--transition-framer-hover);
    color: var(--text-color-secondary);
    color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.50);
    cursor: text;
    max-width: calc(100% - 30px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.large{
      top: 30px;
      font-size: 17px;
      font-weight: 500;
      color: #e5e7e9;
      color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.50);
      left: 20px;
    }

    &.focused{
      top: 13px;
      font-size: 14px;
      color: var(--brand-color);
      color: var(--button-background-color);

      &.default{
        left: 17px;
      }

      &.large{
        font-size: 14px;
        top: 20px;
      }
    }
    
    &.has-value{
      top: 13px;
      font-size: 14px;

      &.default{
        left: 17px;
      }

      &.large{
        color: #506a81;
        color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.5);
        top: 20px;
      }
    }

    &.error{
      color: var(--input-border-color-error) !important;
    }
  }
  &-valid {
    position: absolute;
    top: 20px;
    right: 20px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--brand-color);
    background: var(--button-background-color);
    opacity: 0;
    transition: 0.25s ease-in;

    &.inactive {
      opacity: 0;
    }

    & svg {
      width: 12px;
    }
  }
  &-tooltip{
    & .ant-tooltip-inner{
      border-radius: 12px;
    }
  }
}
