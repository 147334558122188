.container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: var(--background-color);
  opacity: 1;
  transition: opacity var(--transition-framer-enter);

  &.containerExit{
    transition: 0.32s cubic-bezier(0.36, 0, 0.66, -0.16);
    opacity: 0;
  }
}
.logo{
  width: 150px;
  height: auto;
  opacity: 0;
  transform: translateY(200%);
  transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.6s cubic-bezier(0.22, 1, 0.36, 1);

  & path {
    fill: rgba(var(--title-color-r), var(--title-color-g), var(--title-color-b), 0.65);
  }

  &.logoEntry{
    opacity: 1;
    transform: translateY(0%);
  }
  &.logoExit{
    transition: transform var(--transition-framer-exit), opacity var(--transition-framer-exit);
    transform: translateY(-100%);
    opacity: 0;
  }
}