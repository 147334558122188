.container{
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0 11px 0;
  height: 36px;
  background-color: #fff;
  color: #000;
  border-radius: 9px;
  cursor: pointer;
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  box-shadow: 0 0.6px 1.1px -1.1px rgba(0, 0, 0, 0.26), 0 1.9px 3.5px -2.3px rgba(0, 0, 0, 0.24), 0 5.1px 9.2px -3.4px rgba(0, 0, 0, 0.19), 0 16px 28.8px -4.5px rgba(0, 0, 0, 0.03);
  transition: 0.18s cubic-bezier(0.22, 1, 0.36, 1);

  &:hover{
    box-shadow: 0 8px 16px 0 rgba(71, 84, 103, 0.12);
    transform: scale(1.03);
  }
}
.logo{
  display: flex;
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 6px;
}