.image-tapform-logo {
  &.blue {
    & path {
      fill: var(--blue-color);
    }
  }
  &.white {
    & path {
      fill: var(--white-color);
    }
  }
}
