.watermark-component{
  &-container{
    display: flex;
    align-items: center;
    height: 45px;
    border-radius: 22.5px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 0 20px;
    cursor: pointer;
    background: rgba(0,0,0,0.80);
    border-radius: 22.5px;
    transition: 0.50s cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: 0 5px 25px 0 rgba(0,0,0,0);

    @media screen and (min-width: 1025px){
      &:hover{
        transform: scale(1.04);
        box-shadow: 0 5px 25px 0 rgba(0,0,0,0.50);
      }
    }
  }
  &-label{
    margin-right: 10px;
    font-size: 14.5px;
    font-weight: 600;
    color: var(--watermark-label-color);
  }
  &-logo{
    height: 17px;
    width: 75px;
  }
}