html {
  --white-color: #fff;
  --text-color-primary: #0d3051;
  --text-color-secondary: #506981;
  --text-color-white: #ffffff;
  --input-placeholder-color: #c3c7ca;
  --input-border-color-inactive: #c3c7ca;
  --input-border-color-hover: #9ba1a7;
  --input-border-color-focus: #1a9ed9;
  --input-border-color-error: #ff3b30;
  --input-text-color: #041524;
  --input-border-radius: 18px;
  --foreground-color: #edf2f6;
  --background-color: #fff;
  --new-badge-text-color: #0070f3;
  --new-badge-background-color: #eff6ff;
  --new-badge-border-color: #daebff;
  --lead-list-border-color: #e3e3e3;
  --lead-list-avatar-color: #d8d8d8;
  --button-phone-color: #0b9f6e;
  --button-print-color: #8e9da9;
  --button-back-color: #dee6ec;
  --answer-background-color: rgba(22, 193, 182, 0.2);
  --answer-text-color: #054533;
  --checkbox-inactive-color: #dae1e7;
  --checkbox-active-color: #0b9f6e;
  --back-button-background: transparent;
  --back-button-hover: #ecf0f2;
  --phone-cta-background: #ecf0f2;
  --watermark-background: rgba(0, 0, 0, 0.8);
  --watermark-label-color: #C3C3C3;
  --form-input-option-background: #f3f4f4;
  --form-input-option-active-background: #e8e8e8;
  --phone-input-flag-background: #f6f7f8;
}