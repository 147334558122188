.submit-screen-component{
  &-container{
    display: flex;
    flex-direction: column;
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 40px;
    margin-top: max(15vh, 35px);

    @media screen and (max-width: 1024px){
      padding: 0 18px 70px;
      margin-top: 0;
    }

    @media screen and (max-height: 764px) and (min-width: 1024px){
      margin-top: 0px;
    }
    
    &-top{
      display: flex;
      justify-content: space-between;
      padding-bottom: 55px;
      align-items: center;

      @media screen and (max-width: 1024px){
        flex-direction: column;
        max-width: 622px;
        padding-bottom: 0;
      }

      &-image{
        max-width: 600px;
        height: 480px;
        width: 100%;
        border-radius: 18px;
        margin-right: 52px;
        object-fit: cover;

        @media screen and (max-width: 1024px){
          height: 314px;
          margin-right: 0;
          margin-bottom: 20px;
          min-width: 100%;
        }
      }
      &-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        
        &.no-image{
          max-width: 822px;
          align-items: center;
          justify-content: flex-start;
        }
        @media screen and (min-width: 1025px){
          padding: 0;
        }

        @media screen and (max-width: 1024px){
          align-items: center;
        }

        &-label{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-weight: bold;
          background-color: var(--brand-color);
          background-color: var(--button-background-color);
          border-radius: 17px;
          height: 34px;
          padding: 0 15px;
          margin-bottom: 20px;
          color: var(--white-color);
          color: var(--button-text-color);
          min-width: 75px;
          width: fit-content;
          width: -moz-fit-content;
          @media screen and (max-width: 1024px){
            order: 0;
          }
        }
        &-title{
          font-size: 32px;
          font-weight: bold;
          color: var(--title-color);
          margin-bottom: 12px;
          &.no-image{
            text-align: center;
          }
          @media screen and (max-width: 1024px){
            order: 1;
            font-size: 28px;
            text-align: center;
          }
        }
        &-description{
          font-size: 18px;
          line-height: 1.31;
          color: rgba(var(--title-color-r), var(--title-color-g), var(--title-color-b), 0.7);
          margin-bottom: 24px;
          &.no-image{
            text-align: center;
          }
          @media screen and (max-width: 1024px){
            order: 3;
            text-align: center;
          }
        }
        &-price{
          margin-bottom: 35px;
          @media screen and (max-width: 1024px){
            order: 2;
            margin-bottom: 25px;
          }
          &-label{
            font-size: 18px;
            font-weight: 600;
            color: var(--title-color);
          }
          &-disclaimer{
            font-size: 15px;
            font-weight: normal;
            color: rgba(var(--title-color-r), var(--title-color-g), var(--title-color-b), 0.8);
            margin-top: 4px;
          }
          &.no-image{
            text-align: center;
          }
        }
        &-quote{
          &-holder{
            width: 100%;

            &.no-image{
              max-width: 420px;
            }
          }
          width: 100%;

          @media screen and (max-width: 1024px){
            &-holder{
              order: 4;
            }
          }
        }
        &-visit{
          height: 62px;
          border-radius: 11px;
          font-size: 19px;
          font-weight: 500;
          color: var(--text-color-primary);
          cursor: pointer;
        }
        &-notice{
          width: fit-content;
          display: flex;
          margin-top: 24px;

          @media screen and (max-width: 1024px){
            order: 5;
          }
        }
      }
    }
  }
}