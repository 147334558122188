.logo-image-component{
  height: 42px;
  object-fit: contain;
  &.animate{
    -webkit-animation: sk-bouncedelay-logo 1s infinite ease-in-out both;
    animation: sk-bouncedelay-logo 1s infinite ease-in-out both;
  }
}

@-webkit-keyframes sk-bouncedelay-logo {
  0%, 80%, 100% { -webkit-transform: scale(0.6) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay-logo {
  0%, 80%, 100% { 
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}