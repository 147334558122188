.textarea-component {
  &-container {
    position: relative;
    width: 100%;
  }
  &-textarea {
    padding: 29px 60px 11px 16px;
    font-size: 16px;
    font-weight: 500;
    color: var(--input-text-color);
    background-color: var(--input-background-color);
    width: 100%;
    border-radius: var(--input-border-radius);
    border: solid 2px transparent;
    transition: var(--transition-framer-hover);
    resize: none;
    height: 100px;
    box-shadow: 0 1px 4px 0 rgba(71, 84, 103, 0.25);
    scrollbar-color: var(--input-text-color) transparent;

    &.large{
      font-size: 18px;
      font-weight: 400;
      padding: 44px 40px 20px 20px;
      height: 162px;
    }

    &.error {
      border-color: var(--input-border-color-error) !important;
    }

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(71, 84, 103, 0.12);

      & +.textarea-component-label {
        color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.85);
      }
    }

    &:focus {
      border-color: var(--brand-color);
      box-shadow: 0 0 10px 0 rgba(var(--brand-color), 20%);

      & + .input-component-valid.active {
        opacity: 1;
      }

      & +.textarea-component-label {
        color: var(--button-background-color);
      }
    }

    &:active{
      transition: 0.12s ease;
      box-shadow: 0 8px 16px 0 rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.12), 0 0 0 4px rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.2);
      border-color: var(--button-background-color);
    }
  }
  &-shadow{
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 35px);
    height: 40px;
    background-image: linear-gradient(to top, transparent, var(--input-background-color) 50%);
    border-top-left-radius: 17px;

    &.large{
      height: 55px;
    }
  }
  &-label{
    position: absolute;
    font-size: 16px;
    font-weight: 500;
    left: 15px;
    top: 22px;
    transition: var(--transition-framer-hover);
    color: var(--text-color-secondary);
    color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.50);
    cursor: text;

    &.large{
      font-size: 18px;
      top: 20px;
      left: 20px;
    }

    &.focused{
      top: 13px;
      left: 17px;
      font-size: 14px;
      color: var(--brand-color);

      &.large{
        top: 20px;
        left: 20px;
        font-size: 15px;
      }
    }
    
    &.has-value{
      top: 13px;
      left: 17px;
      font-size: 14px;

      &.large{
        top: 20px;
        left: 20px;
        font-size: 15px;
      }
    }
  }
  &-valid {
    position: absolute;
    top: 20px;
    right: 20px;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--brand-color);
    opacity: 0;
    transition: 0.25s ease-in;

    &.inactive {
      opacity: 0;
    }

    & svg {
      width: 12px;
    }
  }
}
