.button-component{
  width: 100%;
  background-color: var(--brand-color);
  background-color: var(--button-background-color);
  transition: var(--transition-framer-hover);
  height: 62px;
  border-radius: 31px;
  border-radius: var(--button-border-radius);
  padding: 0 20px;
  font-size: 18px;
  font-weight: 600;
  color: var(--white-color);
  color: var(--button-text-color);
  box-shadow: 0 4px 12px 0 rgba(44, 16, 16, 0.15);
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:active{
    &:not(:disabled){
      transform: scale(0.95);
      filter: brightness(0.7);
    }
  }

  @media screen and (min-width: 1025px){
    &:hover{
      &:not(:disabled){
        transform: scale(1.02);
        filter: brightness(1.15);
      }
    }
    &:active{
      &:not(:disabled){
        transform: scale(0.95);
        filter: brightness(0.7);
      }
    }
  }
}