// @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  border: 0;
}
*,
input,
button,
body {
  font-family: 'Inter', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

html {
  --transition-simple: 0.50s cubic-bezier(0.075, 0.82, 0.165, 1);
  --transition-simple-fast: 0.30s cubic-bezier(0.075, 0.82, 0.165, 1);
  --transition-framer-exit: 0.4s cubic-bezier(0.36, 0, 0.66, -0.56);
  --transition-framer-enter: 0.7s cubic-bezier(0.36, 0, 0.66, -0.56);
  --transition-framer-hover: 0.14s cubic-bezier(0.42, 0, 1, 1);
  --transition-framer-tap: 0.1s cubic-bezier(0.42, 0, 1, 1);
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: var(--background-color);
  & body {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;

    &.foreground {
      background-color: var(--foreground-color);
    }
  }

  & #root {
    width: 100%;
    height: 100%;
  }
}
