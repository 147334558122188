@keyframes blinkingBorder {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
  100% {
    border-color: transparent;
  }
}

.container{
  position: relative;
  width: 100%;

  &:active {
    & > input {
      transition: 0.12s ease;
      box-shadow: 0 8px 16px 0 rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.12), 0 0 0 4px rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.2);
      border-color: var(--button-background-color);
    }
  }

  &.containerFocused{}

  &.defaultContainer {}

  &.largeContainer {}
}

.holder{
  display: flex;
  padding: 29px 60px 11px 16px;
  background-color: var(--input-background-color);
  border-radius: var(--input-border-radius);
  border: solid 2px var(--input-background-color);
  transition: 0.25s ease;
  box-shadow: 0 1px 4px 0 rgba(71, 84, 103, 0.25);
  position: relative;
  align-items: flex-end;

  &.holderError {
    border-color: var(--input-border-color-error) !important;
  }

  @media screen and (min-width: 1025px){
    &:hover{
      box-shadow: 0 8px 16px 0 rgba(71, 84, 103, 0.12);
  
      & > .label {
        top: 9px;
        font-size: 15px;
        // color: var(--brand-color);
        // color: var(--button-background-color);
  
        &.largeLabel{
          font-size: 15px;
          top: 18px;
        }
      }
  
      & > .input{
        opacity: 1;
      }
  
      & > .splitter{
        opacity: 1;
      }
    }
  }

  &.holderFocused{
    border-color: var(--button-background-color);
    box-shadow: 0 0 10px 0 rgb(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 20%);
    
    & > .input{
      opacity: 1;
    }

    &.holderErrorError{
      & > .label{
        color: var(--input-border-color-error) !important;
      }
    }
  }

  &.holderActive{
    transition: 0.12s ease;
    box-shadow: 0 8px 16px 0 rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.12), 0 0 0 4px rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.2);
    border-color: var(--button-background-color);
  }

  &.largeHolder{
    padding: 38px 60px 16px 20px;
  }
}

.input{
  font-size: 16px;
  font-weight: 400;
  color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.20);
  opacity: 0;
  transition: opacity 0.25s ease;

  animation-name: blinkingBorder;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }




  &.inputError {
    border-color: var(--input-border-color-error) !important;

    &+label{
      color: var(--input-border-color-error) !important;
    }
  }

  &.inputHasValue{
    opacity: 1;
  }

  &.hasOwnValue{
    color: var(--input-text-color);
  }

  &.defaultInput {}

  &.largeInput {
    font-size: 18px;
  }
}

.label{
  position: absolute;
  font-size: 19px;
  font-weight: 500;
  left: 15px;
  top: 22px;
  transition: var(--transition-framer-hover);
  color: var(--text-color-secondary);
  color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.50);
  cursor: text;
  max-width: calc(100% - 30px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.labelError {
    color: var(--input-border-color-error) !important;
  }
  
  &.largeLabel{
    top: 26px;
    font-size: 21px;
    font-weight: 500;
    left: 20px;
  }

  &.labelHasValue {
    top: 9px;
    font-size: 14px;
    color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.5);

    &.largeLabel{
      font-size: 15px;
      top: 18px;
    }
  }

  &.labelFocused {
    top: 9px;
    font-size: 14px;
    font-weight: 500;
    color: var(--brand-color) !important;
    color: var(--button-background-color) !important;

    &.largeLabel{
      font-size: 15px;
      font-weight: 500;
      top: 18px;
    }
  }

}

.splitter{
  font-size: 16px;
  font-weight: 400;
  transition: 0.25s ease;
  opacity: 0;
  color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.20);

  &.splitterHasValue{
    opacity: 1;
    color: var(--input-text-color);
  }

  &.splitterFocused{
    opacity: 1;
    color: var(--input-text-color);
  }

  &.largeSplitter{
    font-size: 18px;
  }
}

.icon {
  position: absolute;
  right: 20px;
  top: 19px;
  & path {
    fill: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.80);
  }
}

.hiddenInput{
  height: 0;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 0.0001px;
  caret-color: transparent;
}