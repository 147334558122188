.step-window-component{
  &-container{
    display: flex;
    flex-direction: column;
    // height: calc(100% - 75px);
    align-items: center;
    padding: 62px 0 0;
    position: relative;
    // min-height: 490px;
    // justify-content: center;

    @media screen and (max-width: 1024px){
      padding: 42px 0 0;
    }
  }
  &-title{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 12px;
    color: var(--text-color-primary);
    color: var(--title-color);
    max-width: 800px;
    padding: 0 35px;
    text-align: center;

    &.no-description{
      margin-bottom: 39px;
    }

    @media screen and (max-width: 1024px){
      font-size: 28px;
      margin-bottom: 12px;
      padding: 0 18px;

      &.no-description{
        margin-bottom: 39px;
      }

      &.final-step{
        margin-bottom: 14px;
      }
    }
  }
  &-description{
    font-size: 18px;
    font-weight: normal;
    color: rgba(var(--title-color-r), var(--title-color-g), var(--title-color-b), 0.70);
    margin-bottom: 39px;
    max-width: 640px;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 1024px){
      padding: 0 18px;
    }
  }
  &-inputs{
    display: flex;
    padding: 0 35px;
    width: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1024px){
      padding: 0 18px;
    }
  }
  &-shadow{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 140px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--background-color));
    pointer-events: none;
    z-index: 2;
  }
  &-submit{
    &-container{
      display: flex;
      justify-content: center;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 25px;
      opacity: 0;
      animation-name: submitSlideIn;
      animation-fill-mode: forwards;
      animation-duration: 0.7s;
      animation-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
      z-index: 2;

      @-webkit-keyframes submitSlideIn {
        0% {
          -webkit-transform: translateY(calc(100% + 15px)) scale(0.4);
                  transform: translateY(calc(100% + 15px)) scale(0.4);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) scale(1);
                  transform: translateY(0) scale(1);
                  opacity: 1;
                  transform: scale(1);
        }
      }
      @keyframes submitSlideIn {
        0% {
          -webkit-transform: translateY(calc(100% + 15px)) scale(0.4);
                  transform: translateY(calc(100% + 15px)) scale(0.4);
                  opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) scale(1);
                  transform: translateY(0) scale(1);
                  opacity: 1;
      
        }
      }
    }
    min-width: 420px;
    max-width: 420px;
    padding: 0 50px;

    @media screen and (max-width: 1024px){
      min-width: initial;
      width: 394px;
      max-width: calc(100% - 36px);
    }
  }
  &-final{
    &-container{
      display: flex;
      flex-direction: column;
      padding-bottom: 150px;
      min-width: 500px;
      max-width: 500px;

      @media screen and (max-width: 1024px){
        padding-top: 20px;
        min-width: 100%;
        max-width: 100%;
        padding: 20px 0px 150px;
      }
    }
    &-name{
      display: flex;
      margin-bottom: 20px;
    }
    &-first-name{
      margin-right: 20px;
    }
    &-address{
      margin-bottom: 20px;
    }
    &-single-row{
      display: flex;
      margin-bottom: 20px;
      justify-content: space-between;
    }
    &-city{
      width: calc(50% - 10px) !important;
    }
    &-zip{
      width: calc(50% - 10px) !important;
    }
    &-phone{
      margin-bottom: 20px;
    }
    &-notice{
      display: flex;
      flex-direction: column;
      padding: 25px 5px 0;
      text-align: left;
      &-title{
        font-size: 14px;
        font-weight: bold;
        color: var(--title-color);
        margin-bottom: 10px;
      }
      &-description{
        font-size: 14px;
        font-weight: 500;
        color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.50);
      }
    }
  }
  &-loading{
    &-container{
      width: 100%;
      height: calc(100vh - 200px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-logo-spinner{
      height: 55px;
      width: auto;
    }
  }
}