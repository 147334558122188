.header-component{
  &-container{
    padding: 16px 35px 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 74px;
    background-color: var(--background-color);
    position: sticky;
    top: 5px;
    z-index: 1;

    @media screen and (max-width: 1024px){
      padding: 16px 18px 11px;
    }
  }
  &-logo{
    position: absolute;
    width: 150px;
    height: 52px;
    object-fit: contain;
    left: calc(50% - 75px);
  }
}