.container{
  max-width: 220px;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  margin: 10px 10px;
  align-self: stretch;

  @media screen and (max-width: 1024px){
    width: calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}
.holder{
  // overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--form-input-option-background);
  background-color: var(--input-background-color);
  box-shadow: 0 1px 4px 0 rgba(71, 84, 103, 0.25);
  border-radius: 10px;
  border: 2px solid transparent;
  transition: var(--transition-framer-hover);

  &:hover{
    box-shadow: 0 8px 16px 0 rgba(71, 84, 103, 0.12);
    transform: scale(1.03);

    & > span {
      color: var(--input-text-color);
    }

    @media screen and (max-width: 1024px){
      box-shadow: 0 1px 4px 0 rgba(71, 84, 103, 0.25);
      transform: scale(1);
      color: var(--input-text-color);
    }
  }

  &:active{
    border-color: var(--button-background-color);
    box-shadow: 0 8px 16px 0 rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.12), 0 0 0 4px rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.2);
    transform: scale(1);

    & > span {
      color: var(--input-text-color);
    }
  }

  &.holderActive{
    border-color: var(--button-background-color, --brand-color);
    transform: scale(1.05);

    & > span {
      color: var(--input-text-color);
    }
  }
}
.imageHolder{
  position: relative;
  min-height: 218px;
  max-height: 218px;
  display: flex;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  @media screen and (max-width: 1024px){
    min-height: 150px;
    max-height: 150px;
    min-width: 100%;
  }
}
.image{
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.25s ease-in;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  &.imageLoaded{
    opacity: 1;
  }
}
.imageBackground{
  position: absolute;
  top: -2px;
  left: -2px;
  height: 100%;
  width: calc(100% + 4px);
  background-color: rgba(255, 255, 255, 0.15);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
.label{
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color-primary);
  color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.83);
  padding: 25px 35px;
  text-align: center;
  display: flex;
  height: 100%;
  align-items: center;
}