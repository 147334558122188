.phone-input-component{
  &-container{
    display: flex;
    border-radius: var(--input-border-radius);
    border: solid 2px var(--input-background-color, --input-border-color-inactive);
    height: 62px;
    background-color: var(--input-background-color, --white-color);
    position: relative;
    box-shadow: 0 1px 4px 0 rgba(71, 84, 103, 0.25);
    transition: var(--transition-framer-hover);

    &.dropdown-active{
      @media screen and (min-width: 1025px){
        margin-bottom: 290px;
      }
    }

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(71, 84, 103, 0.12);

      & .phone-input-component-input-label {
        color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.85);
      }
    }
    
    &.focused{
      border-color: var(--brand-color);
      box-shadow: 0 0 10px 0 rgb(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 20%);

      & .phone-input-component-input-label {
        color: var(--button-background-color);
      }
    }
    
    &:active{
      transition: 0.12s ease;
      border-color: var(--brand-color);
      box-shadow: 0 8px 16px 0 rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.12), 0 0 0 4px rgba(var(--button-background-color-r), var(--button-background-color-g), var(--button-background-color-b), 0.2);
      border-color: var(--button-background-color);
    }

    &.error {
      border-color: var(--input-border-color-error) !important;
      & label {
        color: var(--input-border-color-error) !important;
      }
    }
  }
  &-region{
    height: 100%;
    background-color: var(--phone-input-flag-background);
    background-color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.05);
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    border-top-left-radius: var(--input-border-radius);
    border-bottom-left-radius: var(--input-border-radius);
    cursor: pointer;
    user-select: none;
    padding-right: 10px;
    &-icon{
      margin-left: 3px;
      & path:nth-child(2){
        stroke: var(--input-text-color);
      }
    }
  }
  &-dropdown{
    display: none;
    width: 100%;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.2);
    border-radius: var(--input-border-radius);
    background-color: #fff;
    background-color: var(--input-background-color);
    flex-direction: column;
    overflow: hidden;

    @media screen and (min-width: 1025px){
      position: absolute;
      top: 68px;
      left: 0;
    }

    @media screen and (max-width: 1024px){
      position: fixed;
      bottom: 0;
      left: 0;
    }

    &.active{
      display: flex;
    }

    &-top{
      padding: 22px 25px;
      border-bottom: 1px solid #d2d3d4;
      border-color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.25);
      display: flex;
      align-items: center;
    }

    &-input{
      font-size: 16px;
      font-weight: normal;
      color: var(--text-color-primary);
      background-color: var(--input-background-color);
      color: var(--input-text-color);

      &-icon{
        margin-right: 10px;
        & path {
          fill: var(--input-text-color);
        }
      }
    }

    &-option{
      display: flex;
      padding: 10px;
      border-radius: 8px;
      align-items: center;
      position: relative;
      cursor: pointer;
      
      &.selected{
        background-color: #f6f7f8;
        background-color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.30);
      }

      &-flag{
        font-size: 25px;
        margin-right: 15px;
      }

      &-text{
        font-size: 16px;
        color: var(--text-color-primary);
        color: var(--input-text-color);
      }

      &-checkmark{
        position: absolute;
        right: 20px;
        top: 20px;
        & path:nth-child(2) {
          stroke: var(--input-text-color);
        }
      }

      &-container{
        height: 205px;
        overflow-y: scroll;
        padding-bottom: 40px;
        padding: 10px 10px 40px;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }

        @media screen and (max-width: 1024px){
          height: calc(100% - 66px);
        }
      }
      &-shadow{
        position: absolute;
        left: 0;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(to bottom, transparent, rgba(var(--input-background-color-r), var(--input-background-color-g), var(--input-background-color-b), 1));
        border-radius: 10px;
        overflow: hidden;

        @media screen and (min-width: 1025px){
          top: 222px;
        }

        @media screen and (max-width: 1024px){
          bottom: 0;
        }
      }
    }

    &-mobile{
      position: fixed;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      transition: 0.25s ease-in;
      background-color: #ffffff;
      height: 70%;
      padding-top: 40px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      z-index: 2;
      width: 100%;

      &.active{
        transform: translateY(0);
      }

      &-shadow{
        opacity: 0;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        background-color: rgba(0, 0, 0, 0.8);
        transition: 0.25s ease-in;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;

        &.active{
          display: block;
        }

        &.active-anim{
          opacity: 1;
        }
      }
    }
  }
  &-input{
    padding: 28px 35px 8px 15px;
    font-size: 17px;
    font-weight: normal;
    color: var(--text-color-primary);
    width: 100%;
    border-top-right-radius: var(--input-border-radius);
    border-bottom-right-radius: var(--input-border-radius);
    background-color: var(--input-background-color);
    color: var(--input-text-color);

    &-holder{
      position: relative;
      width: 100%;
    }
    &-label{
      position: absolute;
      top: 10px;
      left: 15px;
      font-size: 14px;
      font-weight: 500;
      color: #9ba1a7;
      color: rgba(var(--input-text-color-r), var(--input-text-color-g), var(--input-text-color-b), 0.50);
      transition: var(--transition-framer-hover);

      &.focused{
        color: var(--brand-color);
      }
    }
    &-valid{
      position: absolute;
      top: 18px;
      right: 20px;
      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;
      border-radius: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--brand-color);
      opacity: 0;
      transition: 0.25s ease-in;

      &.inactive {
        opacity: 0;
      }

      &.active{
        opacity: 1;
      }

      & svg {
        width: 12px;
      }

      &:active{
        opacity: 1;
      }
    }
  }
  &-tooltip{
    & .ant-tooltip-inner{
      border-radius: 12px;
    }
  }
}